import React from "react";
import Img from "gatsby-image/withIEPolyfill";

const ImageList = ({data}) => {
    const {imageList, title} = data;
    return (
        <div className={`certified-image-block`}>
            <h2 className={'h2-title'}>{title}</h2>

            <div className={'image-container flex-center'}>
                {imageList?.map((el, item) => (
                    !!el?.image?.remoteFile?.childImageSharp && (
                        <div className={'certified-image'} key={`${el.image}-${item}`}>
                            <Img
                                objectPosition="center top"
                                fluid={el.image.remoteFile.childImageSharp.fluid}
                            />
                        </div>
                    )
                ))}
            </div>
        </div>
    )
}

export default ImageList
