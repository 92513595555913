import React, {useEffect, useState} from "react";
import Img from "gatsby-image";
import VideoPopUp from "../homepage/video-popup";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import startSideAnimation from "../../helper/startSideAnimation";

const VideoImageBlock = ({isVisible, data, side}) => {
    const {link, imageSource} = data || {};
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        !!link && setOpen(!open);
    };
    const [startAnimation, setStartAnimation] = useState(false);
    const animationSide = startSideAnimation(side);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);
    return (
        <>
            {!!imageSource?.remoteFile?.childImageSharp && (
                <>
                    <button className={`video-container ${side ? side : ''} ${!link ? 'image' : ''}`}
                       onClick={handleOpen}>
                        <Img
                            style={{
                                transform: startAnimation
                                    ? animationSide.endImage
                                    : animationSide.startImage,
                                transition: `all .75s ease .5s`
                            }}
                            fluid={
                                imageSource.remoteFile.childImageSharp.fluid
                            }
                        />
                        {!!link && (<div className={'play-button-container absolute'}>
                            <div className={'play-button-outer'}>
                                <div
                                    className={'play-button-inner absolute'}
                                    style={{
                                        transform: startAnimation
                                            ? animationSide.endButton
                                            : animationSide.startButton,
                                        transition: `all .75s ease .7s`
                                    }}
                                >
                                    <div className={'play-button absolute'}
                                         aria-label={'play-button'}
                                         id={'play-button'}/>

                                        <VideoPopUp
                                            open={open}
                                            handleOpen={handleOpen}
                                            videoId={link}
                                        />
                                </div>
                            </div>
                        </div>)}
                    </button>
                </>
            )}
        </>
    )
}

export default VideoImageBlock