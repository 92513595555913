import React from "react";
import ReactModal from "react-modal";

const VideoPopUp = ({handleOpen, videoId, open}) => {
    const modalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
    };

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={handleOpen}
            className={'modal-video'}
            style={modalStyles}
            ariaHideApp={false}
            htmlOpenClassName={'video-popup__open'}>
            <div className="modal-video-body">
                <div className="modal-video-inner">
                    <div className="modal-video-movie-wrap">
                        <button onClick={handleOpen}
                                className={'modal-video-close-btn'}
                                aria-label='Close'
                                type='submit'/>
                        <iframe
                            title={`https://www.youtube.com/embed/${videoId}`}
                            width='460'
                            height='230'
                            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                            frameBorder='0'
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen/>
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

export default VideoPopUp