import React from 'react';
import ServiceInternalsItems from "./internals-items";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";

const ServiceInternalsList = ({fieldGroupName, list, title }) => {
    const sanitizeOpt = sanitizeOptions();
    return (
        <div className={'service-internals__wrapper'}>
            {Array.isArray(list) && (
                <>
                    {!!title && (
                        <h2 className={'h2-title'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(title, sanitizeOpt.title)}}/>
                    )}
                    <ul className={'service-internals__list'}>
                        {list.map((element, item) => (
                            <ServiceInternalsItems
                                key={`${fieldGroupName}-${item}`}
                                item={item}
                                element={element}
                            />
                        ))}
                    </ul>
                </>
            )}
        </div>
    )
}

export default ServiceInternalsList;