import React, {useEffect, useState} from "react";
import sanitizeHtml from 'sanitize-html';
import ContentListItems from "../content-list-items";
import sanitizeOptions from "../../helper/sanitizeOptions";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const WhoWeAre = ({isVisible, data}) => {
    const sanitizeOpt = sanitizeOptions();
    const {whoWeAre, socialLinks} = data;
    const {amountBlock, descriptionBlock} = whoWeAre;
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <div className={'careers__who-we-are-block'}>
            {!!amountBlock && (
                <ul className={'careers__amount-block'}>
                    <ContentListItems
                        useCounter={startAnimation}
                        data={amountBlock}
                    />
                </ul>
            )}
            <div className={'careers__description-block'}>
                {!!descriptionBlock?.title && (
                    <div
                        className={'h2-title-wrapper'}
                        style={{overflow: 'hidden'}}
                    >
                        <h2
                            className={'h2-title'}
                            style={{
                                transform:`translateY(${startAnimation? 0: '100%'})`,
                                transition: 'all .75s ease'
                            }}
                            dangerouslySetInnerHTML={{__html: sanitizeHtml(descriptionBlock.title, sanitizeOpt.title)}}
                        />
                    </div>
                )}
                {!!descriptionBlock?.text && (
                    <div
                        className={'text'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(descriptionBlock.text, sanitizeOpt.title)}}
                        style={{
                            opacity:`${startAnimation? 1: 0}`,
                            transition: 'all .75s ease .2s'
                        }}
                    />
                )}
                {!!socialLinks && (
                    <div
                        className={'media-links-container-wrapper'}
                        style={{
                            overflow: 'hidden'
                        }}
                    >
                        <div
                            className={'media-links-container'}
                            dangerouslySetInnerHTML={{__html: sanitizeHtml(socialLinks, sanitizeOpt.title)}}
                            style={{
                                transform:`translateX(${startAnimation? 0: '-100%'})`,
                                transition: 'all .75s ease .4s'
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default WhoWeAre