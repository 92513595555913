import React from 'react';
import WhatWeOfferItem from "./WhatWeOfferItem";
import Title from './Title';
import TrackVisibility from 'react-on-screen';

const WhatWeOffer = ({data}) => {
    const {title, whatWeOfferItems} = data;

    return (
        <div className={'careers__what-we-offer-block'}>
            {!!title && (
                <TrackVisibility once offset={500}>
                    <Title
                        title={title}
                    />
                </TrackVisibility>
            )}
            {!!whatWeOfferItems && (
                <ul className={'careers__what-we-offer-items'}>
                    {whatWeOfferItems.map((el, index) => (
                        <TrackVisibility
                            className={'lazyload-wrapper'}
                            key={index}
                            once
                            offset={200}>
                        <WhatWeOfferItem
                                key={index}
                                el={el}
                            />
                        </TrackVisibility>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default WhatWeOffer