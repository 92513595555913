import React, {useEffect, useState} from "react"
import Testimonials from "../testimonials-type-b";
import TrackVisibility from 'react-on-screen';
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const TestimonialsHomeAnimation = ({isVisible, testimonials, homepage}) => {
    const testimonialsCustomFields = homepage;
    const [animateTitle, setAnimateTitle] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if (setIsVisible) {
            window.setTimeout(() => {
                setAnimateTitle(true);
            }, 700);
        }
    }, [isVisible]);

    return (
        <div className={'projects-and-testimonials-container'}>
            <Testimonials
              isVisible={isVisible}
              testimonialsItems={testimonials}
              testimonialsCustomFields={testimonialsCustomFields}/>
        </div>
    )
};

const TestimonialsHome = ({testimonials, homepage}) => {
    return (
        <TrackVisibility once offset={700}>
            <TestimonialsHomeAnimation
                testimonials={testimonials}
                homepage={homepage}
            />
        </TrackVisibility>
    )
}

export default TestimonialsHome

