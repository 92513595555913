import React from "react";
import sanitizeOptions from "../../helper/sanitizeOptions";
import sanitizeHtml from "sanitize-html";
import Img from "gatsby-image";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";


const WhyWorkWith = ({data}) => {
    const sanitizeOpt = sanitizeOptions();
    const windowSize = useWindowSize().width;
    const windowSizeMobile = constants.MOBILE_WIDTH;
    const {
            title,
            text,
            imageDesktop,
            imageMobile,
          } = data;
    return (
        <>
            <div className={'why-work-with-wrapper'}>
                <h2 className={'h2-title'}>{title}</h2>

                <div className={'titles-wrap'}
                     dangerouslySetInnerHTML={{__html: sanitizeHtml(text, sanitizeOpt.title)}}
                />

                <div className={'column-wrapper__img'}>
                    <div className={'column-wrapper'}>
                        <div
                            className={'column-wrapper__main-link'}>
                            {!(windowSize < windowSizeMobile) ?
                                (!!imageDesktop?.localFile?.childImageSharp && (
                                    <Img className={'column-wrapper__main-image desktop'}
                                         fluid={imageDesktop.localFile.childImageSharp.fluid}/>)) :
                                (!!imageMobile?.localFile?.childImageSharp && (
                                    <Img className={'column-wrapper__main-image mobile'}
                                         fluid={imageMobile.localFile.childImageSharp.fluid}/>))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyWorkWith
