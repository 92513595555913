import React, {useEffect, useState} from "react";
import Img from "gatsby-image";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const WhatWeOfferItem = ({isVisible, el}) => {
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <li
            className={`${el.position}`}
            style={{
                overflow: 'hidden'
            }}
        >
            {!!el?.image?.remoteFile?.childImageSharp && (
                <Img
                    style={{
                        transform:`translateX(${startAnimation? 0: '-300%'})  rotate(${startAnimation? 0: '-90deg'})`,
                        transition: 'all .75s ease'
                    }}
                    fluid={
                        el.image.remoteFile.childImageSharp.fluid
                    }
                />
            )}
            {!!el.text && !el.htmlText && (
                <div className={'text'}>
                    <div
                        className={'border-top'}
                        style={{
                            width:`${startAnimation? '100%': 0}`,
                        }}
                    />
                    <span
                        style={{
                            opacity:`${startAnimation? 1: 0}`,
                            transition: 'all .75s ease .5s'
                        }}
                    >{el.text}</span>
                </div>
            )}
            {!!el.htmlText && (
                <div className={'text'}>
                    <span
                        style={{
                            opacity:`${startAnimation? 1: 0}`,
                            transition: 'all .75s ease .5s'
                        }}
                        dangerouslySetInnerHTML={{ __html: el.htmlText }}
                    ></span>
                </div>
            )}
        </li>
    )
}

export default WhatWeOfferItem