import React, {useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import MageButton from '../mageButton';
import sanitizeOptions from '../../helper/sanitizeOptions';
import Arrows from "../service-categories/Arrows";
import useTitleAnimation from "../../hooks/useTitleAnimation";
import scrollToBlock from "../../helper/scrollToBlock";
import addVideoLink from "../../helper/addVideoLink";
import * as constants from "../../helper/constants";

const WhatWeDoHeader = ({page, letsChatBlockId}) => {
    const sanitizeOpt = sanitizeOptions();
    const isHtmlProcessed = useTitleAnimation();

    useEffect(() => {
        //To add the src attribute for video tag in admin content
        addVideoLink(constants.SITE_BASE_URL);
    }, []);

    const scrollToChart = () => {
        scrollToBlock(`.${letsChatBlockId}-wrapper`);
    };

    return (
        <div className={'what-we-do__header'}>
            {!!page.title && (
                        <div
                            className={'what-we-do__header-content'}
                            style={{opacity: isHtmlProcessed ? 1 : 0}}
                            dangerouslySetInnerHTML={{__html: sanitizeHtml(page.title, sanitizeOpt.contentData)}}
                        />
            )}
            <div className={'page-content what-we-do-list__lets-chat-container'}>
                <Arrows/>
                <div
                    className={'what-we-do-list__lets-chat-button'}
                    style={{
                        opacity: isHtmlProcessed ? 1 : 0,
                        transition: 'opacity .75s ease'

                    }}
                >
                    <MageButton
                        clickEvent={scrollToChart}
                        color={`#EC5C2B`}
                        size={180}
                        text={page.letsChatButton}
                        textColor={`white`}/>
                </div>
                {!!page.titleContent && (
                    <div className={'what-we-do-list__lets-chat-content'}
                         style={{
                             opacity: isHtmlProcessed ? 1 : 0,
                         }}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(page.titleContent, sanitizeOpt.title)}}/>
                )}
            </div>
        </div>
    );
};

export default WhatWeDoHeader;