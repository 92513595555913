import React, {useEffect, useState} from "react";
import Img from "gatsby-image";
import scrollToBlock from "../../helper/scrollToBlock";
import * as constants from "../../helper/constants";

const CTABlock = ({data, letsChatBlockId}) => {
  const scrollToChart = () => {
    scrollToBlock(`.${letsChatBlockId}-wrapper`);
  };
  const {image, buttonText, fieldGroupName, title} = data;
  return (
    <div className={`dark-background cta-block flex-container ${fieldGroupName}`}>
        <div className={'left-container'}>
          {!!image?.remoteFile?.childImageSharp && (
            <div>
              <Img
                objectPosition={'center top'}
                fluid={image.remoteFile.childImageSharp.fluid}
              />
            </div>
          )}
        </div>
        <div className={'right-container'}>
          <h5 className={'h5-title white'}>{title}</h5>
          <button className={'mc-button-secondary transition-link white'} onClick={scrollToChart}>{buttonText}</button>
        </div>

    </div>
  )
}

export default CTABlock
