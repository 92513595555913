import React from 'react';
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";

const ServiceInternalsItems = ({element}) => {
    const sanitizeOpt = sanitizeOptions();
    return (
        <li>
            <div className={'service-internals__content'}>
                {!!element?.description && (
                    <div className={'service-internals__description orange-icon-title'}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(element.description, sanitizeOpt.title)}}/>
                )}
            </div>
        </li>
    )
}

export default ServiceInternalsItems;