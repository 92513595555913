import React, {useEffect, useState} from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { normalizePath } from "../../utils/get-url-path";
import Img from "gatsby-image";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";
import sanitizeHtml from "sanitize-html";
import sanitizeOptions from "../../helper/sanitizeOptions";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const Items = ({ page, testimonialsCustomFields, isVisible }) => {
    const imageWeb = page?.testimonials?.image?.remoteFile?.childImageSharp?.fluid;
    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.MOBILE_WIDTH;
    const sanitizeOpt = sanitizeOptions();
    const [animateTitle, setAnimateTitle] = useState(false);
    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if (setIsVisible) {
            window.setTimeout(() => {
                setAnimateTitle(true);
            }, 700);
        }
    }, [isVisible]);

    return (
        <>
            <div
                style={{
                    transition: 'all .75s ease',
                    opacity: `${animateTitle ? 1 : 0}`,
                }}
            >
                <h2 className={'h2-title black h3-title-services'}
                    dangerouslySetInnerHTML={{__html: sanitizeHtml(page?.testimonials?.title, sanitizeOpt.contentData)}}
                />
            </div>
            <div key={page.id}
                className={`project-item ${!!page?.slug ? page.slug : ''}`}>
                <h5 className={'h5-title black" data-animated-group="animate-description body-font-styles'}>
                  {page?.testimonials?.testimonial}
                </h5>
                <div className={'name-position-wrapper'}>
                  {!!imageWeb && isMobile &&
                    <Img className={'image-icon img-mob'}
                         fluid={page.testimonials.image.remoteFile.childImageSharp.fluid}/>}
                  <div>
                    <h4 className={'h4-title black testimonial-name h4-title-bold-services'}>{page?.testimonials?.authorName}</h4>
                    <p className={'text paragraph-secondary black testimonial-company'}>
                      {page?.testimonials?.authorPosition}
                    </p>
                  </div>
                </div>
                {!!testimonialsCustomFields && !!page?.testimonialsCustomFields?.caseStudy && (
                  <AniLink
                    paintDrip
                    duration={1}
                    hex="#ed5d2b"
                    to={normalizePath(page.testimonialsCustomFields.caseStudy.uri)}
                    className={'transition-link paragraph-primary__bold possibilities-link mc-link mc-button-secondary right eye-icon'}>
                    {testimonialsCustomFields.button}
                  </AniLink>
                )}
            </div>
        </>
    )
}

export default Items
