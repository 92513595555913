import React, {useEffect, useState} from "react";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const Title = ({isVisible, title}) => {
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <h2
            className={'h2-title'}
            style={{
                overflow: 'hidden'
            }}
        >
            <div
                style={{
                    transform:`translateY(${startAnimation? 0: '-100%'})`,
                    transition: 'all .75s ease 1s'
                }}
            >
                {title}
            </div>
        </h2>
    )
}

export default Title