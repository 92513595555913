import React, {useEffect, useState} from 'react';

const Arrows = () => {
    const arrowsLength = 7;
    const arrowHeight = 45;
    const arrowsArr = Array(arrowsLength).fill(undefined);
    const [containerHeight, setContainerHeight] = useState(0);
    const [top, setTop] = useState(-arrowHeight);
    const [scale, setScale] = useState(0);
    const Arrow = () => {
        return (
            <div
                style={{
                    height: `${arrowHeight}px`
                }}
            >
                <i className={'mc-icon mc-icon--arrow mc-icon--arrow-d'}/>
            </div>
        )
    };

    const calculateContainerHeight = () => {
        return (arrowsLength - 3) * arrowHeight;
    }

    useEffect(() => {
        setContainerHeight(calculateContainerHeight());
        setScale(1)

        let iterator = -arrowHeight;
        const interval = setInterval(() => {
            if (iterator === arrowHeight) {
                setTop(-iterator);
                iterator = -iterator
            } else {
                iterator++;
                setTop(iterator);
            }
        }, 30);

        return () => clearInterval(interval);
    }, []);


    return (
        <div
            className={'what-we-do-list__lets-chat-arrows'}
            style={{
                height: containerHeight,
                overflow: 'hidden',
                position: 'relative',
                transform: `scaleY(${scale})`,
                transition: "all 1.5s ease"
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: top,
                    // transition: top === -arrowHeight? 'none': 'all 0.3s ease'
                }}
            >
                {arrowsArr.map((item, index) => {
                    return <Arrow key={index}/>
                })}
            </div>

        </div>
    );
};

export default Arrows;