import React, {useEffect, useState} from "react";
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";
import sanitizeOptions from "../../helper/sanitizeOptions";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import VideoImageBlock from "../../components/video-image-block";

const OurEvents = ({isVisible, data}) => {
    const sanitizeOpt = sanitizeOptions();
    const {button, image, text, title} = data;
    const absoluteLinksPattern = new RegExp('^(ftp|http|https):\\/\\/[^ "]+$');
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <div className={'careers__our-events-block'}>
            <div className={'careers__description-block'}>
                {!!title && (
                    <h2
                        className={'h2-title'}
                        style={{
                            overflow: 'hidden'
                        }}
                    >
                        <div
                            style={{
                                transform: `translateY(${startAnimation ? 0 : '-100%'})`,
                                transition: 'all .75s ease .5s'
                            }}
                        >{title}</div>
                    </h2>
                )}
                {!!text && (
                    <div
                        className={'text'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(text, sanitizeOpt.title)}}
                        style={{
                            opacity: `${startAnimation ? 1 : 0}`,
                            transition: 'all .75s ease 1s'
                        }}
                    />
                )}
                {!!button?.text && (
                    <div className={'link'}>
                        {!absoluteLinksPattern.test(button.link)
                            ? <AniLink
                                paintDrip
                                duration={1}
                                hex="#ed5d2b"
                                to={normalizePath(button.link)}
                                className={'transition-link mc-link mc-button-secondary eye-icon'}>
                                {button.text}
                            </AniLink>
                            : <a href={button.link}
                                 target='_blank'
                                 rel='noreferrer'
                                 className={'transition-link mc-link mc-button-secondary eye-icon'}
                            >{button.text}</a>
                        }
                    </div>
                )}
            </div>
            <VideoImageBlock
                isVisible={isVisible}
                data={image}
            />
        </div>
    )
}

export default OurEvents