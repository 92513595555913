import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../components/layout';
import TestimonialsHome from "../components/homepage/testimonials";
import {Helmet} from "react-helmet"
import LazyLoad from "react-lazyload";
import WhatWeDoHeader from "../components/service-categories/what-we-do-header";
import CTABlock from "../components/cta-block";
import WhoWeAre from "../components/careers-who-we-are";
import TrackVisibility from "react-on-screen";
import OurEvents from "../components/careers-our-events";
import TitleLists from "../components/title-lists";
import WhatWeOffer from "../components/careers-what-we-offer";
import ImageList from "../components/ForAgencies";
import ServiceInternalsList from "../components/service-internals";
import WhyWorkWith from "../components/ForAgencies/why-work-with";

const ForAgencies = () => {
    const {data, homepage, testimonials, allCaseStudy} = useStaticQuery(graphql`
        {
            data: wpPage(slug: {eq: "for-agencies"}) {
                title
                uri
                agenciesLandingCustomFields {
                    title
                    titleContent
                    letsChatButton
                    whyWorkWithBlock {
                        fieldGroupName
                        imageDesktop {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 995, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        imageMobile {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 334, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        text
                        title
                    }
                    howItHelps {
                        fieldGroupName
                        title
                        threeColumns
                        list {
                            description
                        }
                    }
                    certificatImageBlock {
                        fieldGroupName
                        imageList {
                            fieldGroupName
                            image {
                                mediaItemUrl
                                remoteFile {
                                    childImageSharp {
                                        fluid(quality: 90) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                    goingGlobal {
                        fieldGroupName
                        whatWeOfferItems {
                            fieldGroupName
                            text
                            image {
                                remoteFile {
                                     childImageSharp {
                                         fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            position
                        }
                        title
                    }
                    rolesAndPositions {
                        fieldGroupName
                        positionList {
                            fieldGroupName
                                text
                        }
                       descriptionBlock {
                            title
                            text
                       }
                    }
                    taBlock1 {
                        fieldGroupName
                        title
                        buttonText
                        image {
                            mediaItemUrl
                            remoteFile {
                                childImageSharp {
                                    fluid(quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    performanceDescription {
                        descriptionBlock {
                          fieldGroupName
                          text
                          titleH2
                        }
                        fieldGroupName
                    }
                    performanceList {
                        fieldGroupName
                        list {
                            description
                        }
                    }
                    ourEvents {
                        title
                        text
                        button {
                            text
                            link
                        }
                        image {
                            link
                            imageSource {
                                remoteFile {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                    titlesList {
                        titleH2
                    }
                    retainEngage {
                        amountBlock {
                            fieldGroupName
                            title
                            text
                        }
                        descriptionBlock {
                            text
                        }
                    }
                    taBlock2 {
                        fieldGroupName
                        title
                        buttonText
                        image {
                          mediaItemUrl
                          remoteFile {
                            childImageSharp {
                                fluid(quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                          }
                        }
                    }
                    testimonialsBlockAgencies {
                       button
                       clientImagesGroup {
                         fieldGroupName
                         linksList
                       }
                       fieldGroupName
                       titleH2
                       titleH5
                    }
                    title
                    titleContent
                    titlesList {
                       fieldGroupName
                       titleH2
                       titleH5
                    }
                }
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }

            serviceCategory: allWpServiceCategory(
                sort: {fields: services___nodes___date, order: ASC}
            ) {
                nodes {
                    id
                    description
                    name
                    services {
                        nodes {
                            id
                            uri
                            title
                        }
                    }
                    serviceCategoryFields{
                        sortOrder
                    }
                }
            }
            homepage: wpPage(isFrontPage: {in: true}){
                homepageCustomFields {
                    testimonialsBlock {
                        fieldGroupName
                        button
                        titleH5
                        titleH2
                    }
                }
            }

            testimonials: allWpTestimonial(
                sort: { fields: date, order: DESC }
            ) {
                nodes {
                    slug
                    title
                    id
                    testimonials {
                        authorName
                        testimonial
                    }
                    testimonialsCustomFields {
                        caseStudy {
                            ... on WpCaseStudy {
                                id
                                uri
                            }
                        }
                        category
                        events {
                            ... on WpEvent {
                                id
                                uri
                            }
                        }
                        fieldGroupName
                    }
                    contentType {
                        node {
                            graphqlSingleName
                        }
                    }
                }
            }
        }
    `);

    const page = data.agenciesLandingCustomFields || {};
    const ctaBlock1 = page.taBlock1 || {};
    const ctaBlock2 = page.taBlock2 || {};
    const certificatImageBlock = page.certificatImageBlock || {};
    const whyWorkWithBlock = page.whyWorkWithBlock || {};
    const letsChatBlockId = 'lets-chat';
    const {seo, additionalMetaTag, title} = data;
    const {rolesAndPositions, ourEvents, goingGlobal, performanceDescription, retainEngage, testimonialsBlockAgencies} = data.agenciesLandingCustomFields;
    const { titlesList } = data.agenciesLandingCustomFields;
    const { titleH2 } = !!titlesList && titlesList;
    const caseStudies = allCaseStudy;
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};
    let caseStudyArray = Array.isArray(testimonials?.nodes) && testimonials?.nodes.filter((el) => (!el?.testimonialsCustomFields?.events));
    caseStudyArray = caseStudyArray.filter((item) => item?.testimonialsCustomFields?.category === 'agencies');
    const whatWeOfferItems = goingGlobal.whatWeOfferItems.map((item) => {
        item.htmlText = item.text;

        return item;
    })
    const agenciesTestimonials = { homepageCustomFields: { testimonialsBlock: testimonialsBlockAgencies }};

    return (
        <Layout
            seoData={seoData}>
            <div className={'agencies'}>
                <Helmet
                    bodyAttributes={{
                        class: 'agencies-page-wrapper'
                    }}
                />
                <WhatWeDoHeader page={page}
                                letsChatBlockId={letsChatBlockId}/>
                <TrackVisibility once offset={300}>
                    <WhyWorkWith data={{
                        title: whyWorkWithBlock.title,
                        text: whyWorkWithBlock.text,
                        imageDesktop: whyWorkWithBlock.imageDesktop,
                        imageMobile: whyWorkWithBlock.imageMobile,
                    }}/>
                </TrackVisibility>

                <WhatWeOffer data={{
                    title: goingGlobal.title,
                    whatWeOfferItems: whatWeOfferItems
                }}/>

                <TrackVisibility once offset={250}>
                    <div className={'roles-and-position-wrap'}>
                        <WhoWeAre data={{
                            whoWeAre: {
                                descriptionBlock: rolesAndPositions.descriptionBlock,
                                amountBlock: rolesAndPositions.positionList
                            }
                        }}/>
                    </div>
                </TrackVisibility>

                <TrackVisibility once offset={350}>
                    <ImageList data={certificatImageBlock} />
                </TrackVisibility>

                <CTABlock data={ctaBlock1}
                          letsChatBlockId={letsChatBlockId}
                />

                <TrackVisibility once offset={350}>
                    <OurEvents data={ourEvents}/>
                </TrackVisibility>

                <div className="page-content case-studies-block-container">
                    <TitleLists
                        data={{
                            customFields: 'case_studies_block',
                            caseStudies: caseStudies,
                            titleH2: titleH2,
                        }}/>
                </div>

                <TrackVisibility once offset={250}>
                    <div className={'engaged-wrap'}>
                        <WhoWeAre data={{
                            whoWeAre: {
                                descriptionBlock: retainEngage.descriptionBlock,
                                amountBlock: retainEngage.amountBlock
                            }
                        }}/>
                    </div>
                </TrackVisibility>

                <CTABlock data={ctaBlock2}
                          letsChatBlockId={letsChatBlockId}
                />
                <TrackVisibility once offset={350}>
                    <div className={'performance-title-wrap'}>
                        <WhoWeAre data={{
                            whoWeAre: {
                                descriptionBlock: {
                                    text: performanceDescription.descriptionBlock?.text,
                                    title: performanceDescription.descriptionBlock?.titleH2,
                                },
                            }
                        }}/>
                    </div>
                    <div className={'performance-list'}>
                        <ServiceInternalsList
                            fieldGroupName={page.performanceList.fieldGroupName}
                            list={page.performanceList.list}
                        />
                    </div>
                </TrackVisibility>

                <div className={'page-content'}>
                    <div className={'bottom-content'}>
                        <TestimonialsHome
                            testimonials={caseStudyArray}
                            homepage={agenciesTestimonials}
                        />
                    </div>
                </div>
            </div>
            {!!letsChatBlockId && (
                <LazyLoad classNamePrefix={letsChatBlockId}>
                    <div id={letsChatBlockId}/>
                </LazyLoad>
            )}
        </Layout>
    );
};

export default ForAgencies;
