export default function startSideAnimation(side) {
    let sideAnimation = {};
    switch (side) {
        case 'right':
            sideAnimation['startImage'] = 'rotate(-45deg) scale(4) translate(-100%, -100%)';
            sideAnimation['endImage'] = 'rotate(-45deg)  scale(1) translate(0, 0)';
            sideAnimation['startButton'] = 'translate(200%, -200%) rotate(90deg)';
            sideAnimation['endButton'] = 'translate(50%, 50%) rotate(0)';
            break;
        default:
            sideAnimation['startImage'] = 'rotate(45deg) scale(4) translate(100%, 100%)';
            sideAnimation['endImage'] = 'rotate(0) scale(1) translate(0, 0)';
            sideAnimation['startButton'] = 'translate(-200%, 200%) rotate(-90deg)';
            sideAnimation['endButton'] = 'translate(-50%, -50%) rotate(0)';
    }
    return sideAnimation;
}
