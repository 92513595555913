import React, { useEffect, useRef, useState } from "react"
import Slider from 'react-slick';
import Item from "./Item";
import ItemImage from "./ItemImage";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import * as constants from "../../helper/constants";
import useWindowSize from "../../hooks/useWindowSize";
import sanitizeHtml from "sanitize-html";
import sanitizeOptions from "../../helper/sanitizeOptions";

const TestimonialsBlock = ({
         testimonialsItems,
         testimonialsCustomFields,
         id,
         carouselSettings,
         isVisible
     }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [oldSlide, setOldSlide] = useState(0);
    const [animateTitle, setAnimateTitle] = useState(false);
    let caseStudyArray = !!id && testimonialsItems.filter((el) => (!!el?.testimonialsCustomFields?.caseStudy && el.testimonialsCustomFields.caseStudy.id === id));
    let resultArray = Array.isArray(caseStudyArray) ? caseStudyArray : testimonialsItems;
    const sliderRef = useRef();
    const sliderImageRef = useRef();

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplaySpeed: 3000,
        fadeIn: true,
        autoplay: false,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: sliderImageRef.current,
        beforeChange: (current, next) => {
          setOldSlide(current)
          setCurrentSlide(next)
        }
    };
    const imageSliderSettings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 1000,
      autoplaySpeed: 3000,
      fade: true,
      autoplay: false,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: sliderRef.current
    };
    useEffect(() => {
      // Disable animation for mobile
      let setIsVisible = disableAnimationForMobile(isVisible);

      if (setIsVisible) {
        window.setTimeout(() => {
          setAnimateTitle(true);
        }, 700);
      }
    }, [isVisible]);

    const windowResizeSize = useWindowSize();
    const isMobile = windowResizeSize.width <= constants.MOBILE_WIDTH;

    return (
        <>
            {Array.isArray(resultArray) && !!resultArray.length && (
              <div
                className={'testimonials-type-b'}
              >
                <div
                  className={'image-container'}>
                  <Slider
                    {...imageSliderSettings}
                    ref={sliderImageRef}
                  >
                    {!isMobile && Array.isArray(resultArray) && resultArray.map((page) => (
                        page && <ItemImage
                        key={page.id}
                        page={page}
                        testimonialsCustomFields={testimonialsCustomFields}
                      />
                    ))}
                  </Slider>
                </div>
                {!!testimonialsCustomFields && (
                  <div
                    className={'testimonials-b-container heading'}
                    style={{
                      transition: 'all .75s ease',
                      opacity: `${animateTitle ? 1 : 0}`,
                    }}
                  >
                    <h5 className={'h5-title black'}>
                        {testimonialsCustomFields.titleH5}
                    </h5>
                  </div>
                )}
                <div className={'testimonials-b-container slider'}>
                  <Slider
                    {...(!!carouselSettings ? carouselSettings : settings)}
                    ref={sliderRef}
                  >
                    {Array.isArray(resultArray) && resultArray.map((page) => (
                        page && <Item
                        key={page.id}
                        page={page}
                        isVisible={isVisible}
                        testimonialsCustomFields={testimonialsCustomFields}
                      />
                    ))}
                  </Slider>
                  <div className={'pagination-wrapper'}>
                    <button
                      className={'prev mc-button-secondary'}
                      disabled={(currentSlide === 0)}
                      onClick={()=>{sliderRef.current.slickPrev()}}
                    >
                      Previous</button>
                    <span className={'counter'}>
                            <span className={`current`}>{currentSlide + 1}</span>/
                            <span className={'count'}>{resultArray.length}</span>
                          </span>
                    <button
                      className={'next mc-button-secondary'}
                      disabled={(currentSlide + 1 >= resultArray.length)}
                      onClick={()=>{sliderRef.current.slickNext()}}
                    >
                      Next</button>
                  </div>
                </div>
              </div>
            )}
        </>
    )
}

export default TestimonialsBlock

