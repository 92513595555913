import React from "react";
import Img from "gatsby-image";

const Items = ({ page, testimonialsCustomFields }) => {
    const imageWeb = page?.testimonials?.image?.remoteFile?.childImageSharp?.fluid;

    return (
      <div key={page.id}
           className={`project-item ${!!page?.slug ? page.slug : ''}`}>
          {!!imageWeb && !page?.testimonialsCustomFields?.events &&
            !!page?.testimonials?.image?.remoteFile?.childImageSharp && (
              <Img className={'image'}
                   fluid={page.testimonials.image.remoteFile.childImageSharp.fluid}/>
            )}
      </div>
    )
}

export default Items
